import { createApp, h, defineAsyncComponent } from 'vue'
import routes from './routes';
import { createI18n } from 'vue-i18n';

const i18n = createI18n({
    legacy: true,
    locale: 'en',
    messages: {
        en: {
            navigation: {
                product: 'Product',
                technology: 'Technology',
                partners: 'Partners',
                about: 'About',
                jobs: 'Jobs',
                login: 'Sign In'
            },
            hero: {
                titleFirstPart: 'The no-',
                titleSecondPart: 'enterprise software',
                tagline: 'Empowering (re)insurers, MGAs, brokers, banks, asset managers, pension funds, and fintechs to achieve more with the power of AI.',
                bookDemo: 'Book demo'
            },
            product: {
                title: 'Our product',
                paragraphOne: 'Connect to other services in a heartbeat. Build applications in weeks, not months or years.',
                paragraphTwo: 'Nuclicore is a compliant and AI-powered enterprise no-code platform. You can build any API or application via drag & drop. Without writing a single line of code.',
                paragraphThree: 'You can use Nuclicore with any system. Your existing core system or a third party solution - Nuclicore is 100% system-agnostic.',
                tariffs: 'Tariffs & Underwriting',
                policy: 'Policy Management',
                billing: 'Billing',
                claims: 'Claims & Payouts',
                accounting: 'Accounting, Reporting & Analytics',
                distribution: 'Distribution',
                premiums: 'Premium Calculation Engine',
                commission: 'Commission Management',
                tax: 'Taxes & Fees System'
            },
            technology: {
                title: 'Our technology',
                paragraphOne: 'Our software is built on the same technology that is powering the world’s biggest internet companies.',
                paragraphTwo: 'It is made from the ground-up, using microservices and cloud infrastructure to be transparent, reliable, flexible, and secure.'
            },
            partners: {
                title: 'Our partners',
                paragraphOne: 'We built our software for regulated industries such as financial institutions. We understand the complexities of the sector and empower companies to build applications and APIs faster and more sustainable. Feel free to reach out to us.'
            },
            about: {
                title: 'About Us',
                paragraphOne: 'We are a software company, servicing regulated industries with an intelligent top-notch software product.',
                paragraphTwo: 'We are a German company, operating from Frankfurt am Main, and store all data at your preferred location.'
            },
            jobs: {
                title: 'Jobs',
                paragraphOne: 'Do You like what we do and would like to join us?',
                paragraphTwo: 'Please send your CV to',
                seeOpenPositions: 'See all open positions'
            },
            demo: {
                title: 'Contact us and book a demo',
                subtitle: 'We will contact you shortly to schedule a demo.',
                name: 'Name',
                email: 'Email',
                toc: 'By submitting the form I confirm that I have read the <a href="https://nuclicore.com/doc/datenschutz.pdf" target="_blank">Privacy Policy</a> and that I agree to the processing of my personal data by Nuclicore for the above mentioned purposes. If I consent, I can revoke my consent at any time.',
                send: 'Send and request demo',
                success: 'Request successfully sent.',
                error: 'There was a problem, please try again.',
                validationError: 'Please enter the correct data and try again.'
            },
            imprint: {
                title: 'Imprint',
                subtitle: 'Information pursuant to § 5 TMG',
                infoTitle: 'Name and address',
                country: 'GERMANY',
                registrationTitle: 'Registration',
                managingDirectors: 'Managing directors',
                contact: 'Contact',
                telephone: 'Telephone',
		taxTitle: 'VAT ID',
		taxContent: 'Sales tax identification number according to Sect. 27a of the Sales Tax Law: DE342675396',
                liabilityContentTitle: 'Liability for contents of this website',
                liabilityContentParagraphOne: 'We are constantly developing the contents of this website and make every effort to provide correct and up-to-date information. In accordance with § 7 para. 1 TMG, we as a service provider are responsible under general law for our own information that we provide for use. According to §§ 8 to 10 TMG, we are not obliged to monitor transmitted or stored third-party information or to investigate circumstances that indicate illegal activity.',
                liabilityContentParagraphTwo: 'Our obligations to remove or block the use of information according to general laws remain unaffected. However, a corresponding liability can only arise at the earliest from the point in time at which we become aware of a concrete violation of the law. If we become aware of such a violation of the law, we will immediately delete the corresponding content from our website.',
                liabilityLinksTitle: 'Liability for links on this website',
                liabilityLinksParagraphOne: 'Our website contains links to other websites whose content we cannot influence. Therefore we do not take responsibility for their content. This is always the responsibility of the respective site operator. At the time of linking, the linked pages were checked for illegal content. Such were not recognisable at the time of linking. A permanent control of the contents of linked pages is not reasonable without concrete evidence of an infringement. However, we will remove such links immediately if we become aware of any infringements.',
                copyrightTitle: 'Copyright notice',
                copyrightParagraphOne: 'The contents of this website are subject to the copyright of the Federal Republic of Germany. The distribution, duplication, processing and any kind of utilisation beyond the limits of copyright law require the written consent of the respective author or creator. Downloads and copies of this website are permitted exclusively for private, not for commercial use.',
                copyrightParagraphTwo: 'Insofar as the contents of this website were not created by the operator, the copyrights of third parties are observed. In particular, third-party content is identified as such. Should you nevertheless become aware of a copyright infringement, please inform us accordingly. If we become aware of any infringements, we will delete such contents immediately.',
            },
            footer: {
                imprintLink: 'Imprint',
                privacyPolicyLink: 'Privacy policy',
                reservedCopyRights: 'All rights reserved'
            }
        },
        de: {
            navigation: {
                product: 'Produkt',
                technology: 'Technologie',
                partners: 'Partner',
                about: 'Über uns',
                jobs: 'Jobs',
                login: 'Anmelden'
            },
            hero: {
                titleFirstPart: 'Die No-',
                titleSecondPart: 'Unternehmenssoftware',
                tagline: 'Mit der Kraft unserer KI erreichen (Rück-)Versicherer, MGAs, Makler, Banken, Vermögensverwalter, Pensionsfonds und Fintechs mehr.',
                bookDemo: 'Demo vereinbaren'
            },
            product: {
                title: 'Unser Produkt',
                paragraphOne: 'Verbinden Sie sich ohne eine Zeile Code zu schreiben mit anderen Services. Erstellen Sie maßgeschneiderte Software innerhalb von Wochen, nicht Monaten oder Jahren.',
                paragraphTwo: 'Nuclicore ist eine konforme und KI-gestützte No-Code-Plattform für Unternehmen. Sie können jede API oder Applikation per Drag & Drop erstellen. Ohne eine einzige Zeile Code zu schreiben.',
                paragraphThree: 'Sie können Nuclicore mit jedem System verwenden. Mit Ihrem vorhandenen Kernsystem oder der Software eines Drittanbieters - Nuclicore ist zu 100 % systemunabhängig.',
                tariffs: 'Tarife & Underwriting',
                policy: 'Policy Management',
                billing: 'Abrechnungen',
                claims: 'Claims & Auszahlungen',
                accounting: 'Accounting, Reporting & Analytics',
                distribution: 'Vertrieb',
                premiums: 'Premium Calculation Engine',
                commission: 'Commission Management',
                tax: 'Steuern & Gebühren'
            },
            technology: {
                title: 'Unsere Technologie',
                paragraphOne: 'Unsere Software basiert auf der gleichen Technologie, die die größten Internetunternehmen der Welt verwenden.',
                paragraphTwo: 'Sie wurde von Grund auf neu entwickelt und nutzt Microservices und Cloud Infrastruktur, um transparent, zuverlässig, flexibel und sicher zu sein.'
            },
            partners: {
                title: 'Unsere Partner',
                paragraphOne: 'Wir haben unsere Software für regulierte Industrien wie die Finanzbranche entwickelt. Wir verstehen die Komplexität des Sektors und versetzen Unternehmen in die Lage, Anwendungen und APIs schneller und nachhaltiger zu entwickeln. Kontaktieren Sie uns gerne.'
            },
            about: {
                title: 'Über uns',
                paragraphOne: 'Wir sind ein Softwareunternehmen, welches der Versicherungsbranche ein intelligentes Softwareprodukt der Spitzenklasse anbietet.',
                paragraphTwo: 'Wir sind ein deutsches Unternehmen mit Sitz in Frankfurt am Main und speichern alle Daten an dem von Ihnen gewünschten Ort.'
            },
            jobs: {
                title: 'Jobs',
                paragraphOne: 'Gefällt Dir was wir tun und möchtest uns unterstützen?',
                paragraphTwo: 'Sende einfach Deinen CV an ',
                seeOpenPositions: 'Alle offenen Stellen anzeigen'
            },
            demo: {
                title: 'Kontaktieren Sie uns und vereinbaren Sie eine Webdemo',
                subtitle: 'Wir werden uns in Kürze mit Ihnen in Verbindung setzen, um einen Termin für eine Demo zu vereinbaren.',
                name: 'Name',
                email: 'E-Mail',
                toc: 'Mit dem Absenden des Formulars bestätige ich, dass ich die <a href="https://nuclicore.com/doc/datenschutz.pdf" target="_blank">Datenschutzerklärung</a> gelesen habe und mit der Verarbeitung meiner persönlichen Daten durch Nuclicore zu den oben genannten Zwecken einverstanden bin. Im Falle einer Einwilligung kann ich meine Zustimmung hierzu jederzeit widerrufen.',
                send: 'Absenden und Demo anfragen',
                success: 'Anfrage erfolgreich gesendet.',
                error: 'Es gab ein Problem, bitte versuchen Sie es erneut.',
                validationError: 'Bitte geben Sie die korrekten Daten ein und versuchen Sie es erneut.'
            },
            imprint: {
                title: 'Impressum',
                subtitle: 'Angaben gemäß § 5 TMG',
                infoTitle: 'Name und Anschrift',
                country: 'DEUTSCHLAND',
                registrationTitle: 'Registrierung',
                managingDirectors: 'Geschäftsführer',
                contact: 'Kontakt',
                telephone: 'Telefon',
		taxTitle: 'Umsatzsteuer-ID',
		taxContent: 'Umsatzsteuer-Identifikationsnummer gemäß 27 a Umsatzsteuergesetz: DE342675396',
                liabilityContentTitle: 'Haftung für Inhalte dieser Website',
                liabilityContentParagraphOne: 'Wir entwickeln den Inhalt dieser Website ständig weiter und bemühen uns, korrekte und aktuelle Informationen bereitzustellen. In Übereinstimmung mit § 7 Abs. 1 TMG sind wir als Diensteanbieter für die eigenen Inhalte, die wir zur Nutzung bereithalten, nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu suchen, die auf eine rechtswidrige Tätigkeit hinweisen.',
                liabilityContentParagraphTwo: 'Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend von unserer Website entfernen.',
                liabilityLinksTitle: 'Haftung für Links auf dieser Website',
                liabilityLinksParagraphOne: 'Unsere Website enthält Links zu anderen Websites, auf deren Inhalt wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Diese liegt immer in der Verantwortung des jeweiligen Seitenbetreibers. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf rechtswidrige Inhalte überprüft. Diese waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links jedoch umgehend entfernen.',
                copyrightTitle: 'Urheberrechtshinweis',
                copyrightParagraphOne: 'Die Inhalte dieser Website unterliegen dem Urheberrecht der Bundesrepublik Deutschland. Die Verbreitung, Vervielfältigung, Bearbeitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.',
                copyrightParagraphTwo: 'Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.',
            },
            footer: {
                imprintLink: 'Impressum',
                privacyPolicyLink: 'Datenschutzerklärung',
                reservedCopyRights: 'Alle Rechte vorbehalten'
            }
        }
    }
})

const SimpleRouterApp = {
    data: () => ({
        currentRoute: window.location.pathname
    }),

    computed: {
        ViewComponent () {
            const matchingPage = routes[this.currentRoute] || '404'
            return defineAsyncComponent(() => import(`./components/${matchingPage}.vue`))
        }
    },

    render () {
        return h(this.ViewComponent)
    },

    created () {
        window.addEventListener('popstate', () => {
            this.currentRoute = window.location.pathname
        })
    }
}
const app = createApp(SimpleRouterApp);
app.use(i18n)
app.mount('#app')
